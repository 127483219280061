<template>
	<div 
	v-loading.fullscreen="uploadloading"
	:element-loading-text="$t('tipsInfo.loadingText')"
	element-loading-spinner="el-icon-loading"
	element-loading-background="rgba(0, 0, 0, 0.8)"
	>
		<!-- 库存列表 -->
		<div class="listHeader">
			<i></i><span>{{$t('ksaWhInventoryPage.cartonInventoryList')}}</span>
		</div>
		<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
		  style="margin:10px 0 0 15px">
		  <el-form-item label prop="tobOrderNo">
		    <el-input v-model="cartonTobOrderNo" type="textarea" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.orderNoColumn')" clearable />
		  </el-form-item>
		  <el-form-item label prop="cartonAwbNo">
		    <el-input v-model="cartonAwbNo" type="textarea" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageNo')" clearable />
		  </el-form-item>
		  <el-form-item>
		    <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button type="primary" icon="el-icon-refresh" @click="getList">{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}</el-button>
		  </el-form-item>
			<el-form-item>
				<el-button type="primary" @click="handleExportCarton()">{{$t('ksaWhInventoryPage.exportCurrentAllCartonInventoryData')}}</el-button>
			</el-form-item>
		</el-form>
		<el-card class="cartonStatistics">
			<div class="statOpt">
				<label>{{$t('ksaWhInventoryPage.totalOrder') + ":"}}</label>
				<animate-number ref="cartonStatisticsOne" from="0" :key="cartonStatistics.totalOrder" :to="cartonStatistics.totalOrder" easing="easeOutQuad"
					mode="manual" duration="1000"></animate-number>
			</div>
			<div class="statOpt">
				<label>{{$t('ksaWhInventoryPage.totalCarton') + ":"}}</label>
				<animate-number ref="cartonStatisticsTwo" from="0" :key="cartonStatistics.totalCarton" :to="cartonStatistics.totalCarton" easing="easeOutQuad"
					mode="manual" duration="1000"></animate-number>
			</div>
			<div class="statOpt">
				<label>{{$t('ksaWhInventoryPage.totalCartonVolume') + "(CBM):"}}</label>
				<span>{{cartonStatistics.totalVolume}}</span>
			</div>
		</el-card>
		
		<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="loading" :data="dataList" border>
		  <el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="tobOrderNo"/>
		  <el-table-column :label="$t('commonInfo.packageNo')" align="center" prop="cartonAwbNo" />
			<el-table-column :label="$t('ksaWhInventoryPage.putOnTime')" align="center" prop="initTime" width="220px" />
			<el-table-column :label="$t('ksaWhInventoryPage.storageTime')" align="center" prop="remarks" />
		</el-table>
		<div class="pageBottom">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="queryParams.page"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="queryParams.limit"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		  ></el-pagination>
		</div>
	</div>
</template>

<script>
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	
	import {downloadStream} from "@/utils/downloadFileStream";
	
	export default {
	  //import引入的组件需要注入到对象中才能使用
	  components: {
	  },
	  data() {
	    //这里存放数据
	    return {
				uploadloading:false,
				queryParams: {
					page:1,
					limit:10,
					tobOrderNos:[],
					awbNos:[],
				},
				cartonTobOrderNo:'',//搜索参数，可多个搜索
				cartonAwbNo:'',//搜索参数，可多个搜索
				cartonLoading:false,
				dataList:[],
				total:0,
				
				// 统计信息
				cartonStatistics:{
					totalOrder:0,
					totalCarton:0,
					totalVolume:0,
				}
			}
		},
		//方法集合
		methods: {
			// 分页条数
			handleSizeChange(size) {
			  this.queryParams.limit = size;
			  this.queryParams.page = 1;
			  this.getList();
			},
			// 分页页数
			handleCurrentChange(page) {
			  this.queryParams.page = page;
			  this.getList();
			},
			// 获取整箱统计信息
			async getCartonStatistics(){
				const res = await this.$http.get("/toborder/wms/carton/stat");
				if (res.code === 200) {
					this.cartonStatistics.totalOrder = res.data.skuCount;
					this.cartonStatistics.totalCaton = res.data.productCount;
					this.cartonStatistics.totalVolume = res.data.productVolume == null?0:res.data.productVolume;
					this.$nextTick(() => {
						this.$refs.cartonStatisticsOne.start();
						this.$refs.cartonStatisticsTwo.start();
					})
					
				} else {
				  this.$message.error(res.msg);
				}
			},
			// 导出整箱信息
			handleExportCarton(){
				this.uploadloading = true;
				const res = this.$http.post("/toborder/wms/carton/exportInventory",null,{
					responseType:'blob',
				}).then(res => {
				    if (res.code != 500) {
							downloadStream(res,'xlsx','整箱库存信息')
				      // window.open("http://awb.dlvd.com/vip_excel/download/" + res.msg);
				    }
						this.uploadloading = false;
				  }).catch(error => {
						this.uploadloading = false;
						this.$message.error(res.msg);
					});
			},
			// 搜索商品编号接口和查询列表接口返回数据一样，但是搜索商品编码接口参数为空，不返回数据，需要判断并调用查询列表接口
			async getList() {
				this.loading = true;
				this.dataList = [];
				const res = await this.$http.post("/toborder/wms/carton/list",this.queryParams);
				
				if (res.code === 200) {
				  this.loading = false;
				  this.dataList = res.data.list;
					this.total = res.data.totalCount;
				} else {
				  this.loading = false;
				  this.$message.error(res.msg);
				}
			},
			
			handleQuery(){
				if(this.cartonTobOrderNo != ''){
					this.queryParams.tobOrderNos = this.strToArr(this.cartonTobOrderNo);
				}else{
					this.queryParams.tobOrderNos = [];
				}
				if(this.cartonAwbNo != ''){
					this.queryParams.awbNos = this.strToArr(this.cartonAwbNo);
				}else{
					this.queryParams.awbNos = [];
				}
				this.queryParams.page = 1;
				this.getList();
				this.getCartonStatistics();
				
			},
			handleReset(){
				this.cartonAwbNo = '';
				this.cartonTobOrderNo = '';
				this.queryParams = {
					page:1,
					limit:10,
					tobOrderNos:[],
					awbNos:[],
				}
				this.getList();
				this.getCartonStatistics();
			},
		},
		created() {
			this.getList();
			this.getCartonStatistics();
		}
	}
</script>

<style scoped>
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px 0 0 50px;
	}
	.cartonStatistics{
		margin: 5px 15px;
	}
	.statOpt{
		display: inline-block;
		margin-right: 40px;
		font-size: 18px;
	}
	.statOpt label{
		margin-right: 10px;
	}
	.statOpt span{
		font-weight: bold;
		color: #409EFF;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
	}
</style>